import React from 'react';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { DataCols } from 'src/components/admin-table/admin-table';
import Button from 'src/components/button/button';
import StatusLabel, { LabelVariety } from 'src/components/status-label/status-label';
import { OrganisationStatus, PaymentMethod, PaymentStatus } from 'src/graphql-types/lta-registration/globalTypes';
import { membershipPackageMember_membershipPackageMember_member as MembershipPackageMember } from 'src/graphql-types/lta-registration/membershipPackageMember';

interface MembershipColsProps {
  onPayClick?: (member: MembershipPackageMember) => void;
}

export const getVariety = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.PAID:
      return 'success';
    case PaymentStatus.PENDING:
      return 'warning';
    case PaymentStatus.NOTPAID:
    case PaymentStatus.FAILED:
      return 'error';
    default:
      return 'neutral';
  }
};

export const getPaymentStatus = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.PAID:
      return 'Paid';
    case PaymentStatus.NOTPAID:
      return 'Not paid';
    case PaymentStatus.PENDING:
      return 'Pending';
    case PaymentStatus.FAILED:
      return 'Failed';
    default:
      return '';
  }
};

export const mapPaymentMethod = (method: PaymentMethod) => {
  switch (method) {
    case PaymentMethod.CARD:
      return 'Credit/debit card';
    case PaymentMethod.DIRECTDEBIT:
      return 'Direct debit';
    case PaymentMethod.INVOICE:
      return 'Invoice';
    case PaymentMethod.NOTSELECTED:
      return 'Not selected';
  }
};

export const mapPaymentSource = (method: PaymentMethod) => {
  switch (method) {
    case PaymentMethod.CARD:
    case PaymentMethod.DIRECTDEBIT:
      return 'Online';
    default:
      return 'Offline';
  }
};

export const getPaymentAmount = (member: MembershipPackageMember) => {
  if (member?.paymentMethod === PaymentMethod.CARD || member?.paymentStatus === PaymentStatus.PAID) {
    return member.totalCost ? member.totalCost : 0;
  }

  return 0;
};

export const getMemberStatusAndVariety = (memberStatus?: OrganisationStatus) => {
  let statusLabel = '';
  let statusVariety: LabelVariety = 'neutral';

  switch (memberStatus) {
    case OrganisationStatus.MEMBER:
      statusVariety = 'success';
      statusLabel = 'Active';
      break;
    case OrganisationStatus.LAPSED:
      statusVariety = 'neutral2';
      statusLabel = 'Lapsed';
      break;
    case OrganisationStatus.NONMEMBER:
      statusVariety = 'neutral2';
      statusLabel = 'Non member';
      break;
    default:
      statusVariety = 'error';
      statusLabel = 'Expired';
      break;
  }

  return {
    statusLabel,
    statusVariety,
  };
};

export const getPaymentLabelAndVariety = (paymentStatus?: PaymentStatus) => {
  let paymentLabel = '';
  let paymentVariety: LabelVariety = 'neutral';

  switch (paymentStatus) {
    case PaymentStatus.PAID:
      paymentVariety = 'success';
      paymentLabel = 'Paid';
      break;
    case PaymentStatus.NOTPAID:
      paymentVariety = 'error';
      paymentLabel = 'Not paid';
      break;
    case PaymentStatus.PENDING:
      paymentVariety = 'warning';
      paymentLabel = 'Pending';
      break;
    case PaymentStatus.FAILED:
      paymentVariety = 'error';
      paymentLabel = 'Failed';
      break;
    default:
      break;
  }

  return { paymentVariety, paymentLabel };
};

export const useMemberCols = ({ onPayClick }: MembershipColsProps): DataCols<MembershipPackageMember> => {
  const { t } = useTranslation();

  return [
    {
      key: 'description',
      title: t('description'),
      getValue: () => 'LTA Venue Registration',
    },
    {
      key: 'payType',
      title: 'Pay type',
      getValue: (m) => mapPaymentMethod(m.paymentMethod),
    },
    {
      key: 'paySource',
      title: 'Pay source',
      getValue: (m) => mapPaymentSource(m.paymentMethod),
    },
    {
      key: 'payDate',
      title: t('pay date'),
      getValue: (m) => dayjs.utc(m.paymentDate).format('DD/MM/YYYY'),
    },
    {
      key: 'status',
      title: 'Status',
      getValue: (m) => (
        <StatusLabel variety={getVariety(m.paymentStatus)}>{getPaymentStatus(m.paymentStatus)}</StatusLabel>
      ),
    },
    { key: 'amount', title: t('amount'), getValue: (m) => `£${getPaymentAmount(m).toFixed(2)}` },
    {
      key: 'action',
      title: t('Action'),
      getValue: (m) =>
        m.paymentMethod === PaymentMethod.INVOICE && m.paymentStatus === PaymentStatus.PENDING ? (
          <Button onClick={() => onPayClick?.(m)} type="button" level="link">
            Pay
          </Button>
        ) : null,
    },
  ];
};
