import graphql from 'graphql-tag';

export const GET_MEMBER = graphql`
  query member($id: String!) {
    member(id: $id) {
      courtCount
      grassCourtCount
      id
      isNew
      membershipPackageID
      packageYear
      paymentDate
      paymentMethod
      paymentReference
      paymentStatus
      salesforceID
      status
      totalCost
    }
  }
`;

export const GET_PAYMENT_HISTORY = graphql`
  query membershipPackageMember($memberID: String!, $membershipPackageID: String!) {
    membershipPackageMember(memberID: $memberID, membershipPackageID: $membershipPackageID) {
      member {
        courtCount
        grassCourtCount
        id
        isNew
        membershipPackageID
        packageYear
        paymentDate
        paymentMethod
        paymentReference
        paymentStatus
        salesforceID
        status
        totalCost
      }
      membershipPackage {
        code
        courtCap
        courtCost
        description
        endDate
        gracePeriodDate
        grassCourtCost
        id
        maxPrice
        name
        organisationType
        packageYear
        paymentMethodAvailable
        pricingOption
        processingFee
        processingFeeType
        renewalPeriod
        renewsOn
        startDate
        status
        type
      }
    }
  }
`;
