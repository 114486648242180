import { useApolloClient } from '@apollo/client';
import { MARK_INVOICE_PAYMENT_AS_PAID } from 'src/components/lta-membership-details/lta-membership-details-queries';
import {
  markInvoiceAsPaidForMember,
  markInvoiceAsPaidForMemberVariables,
} from 'src/graphql-types/lta-registration/markInvoiceAsPaidForMember';
import { membershipPackageMember_membershipPackageMember_member as MembershipPackageMember } from 'src/graphql-types/lta-registration/membershipPackageMember';

const useUpdatePayment = () => {
  const client = useApolloClient();

  return (member: MembershipPackageMember | null, paymentDate: Date | undefined) => {
    if (!member) {
      return Promise.reject('Can not update payment without member');
    }
    if (!paymentDate) {
      return Promise.reject('Can not update payment without a date');
    }

    return Promise.all([
      client.mutate<markInvoiceAsPaidForMember, markInvoiceAsPaidForMemberVariables>({
        mutation: MARK_INVOICE_PAYMENT_AS_PAID,
        variables: {
          id: member?.id,
          paymentDate: paymentDate,
        },
      }),
    ]);
  };
};

export default useUpdatePayment;
