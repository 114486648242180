import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import Layout from 'src/components/layout/layout';
import LTAMemberComponent from 'src/components/lta-member/lta-member';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const LTAMemberPage = () => {
  return (
    <Layout>
      <SEO title="Member" />
      <Router basepath="/lta-memberships/lta-member">
        <LTAMemberRoute path="/:membershipPackageId/:memberName/:memberId" />
      </Router>
    </Layout>
  );
};

interface LTAMemberRouteProps extends RouteComponentProps {
  membershipPackageId?: string;
  memberName?: string;
  memberId?: string;
}

const LTAMemberRoute: React.FC<LTAMemberRouteProps> = ({ membershipPackageId, memberName, memberId }) => {
  return (
    <PrivateRoute>
      <LTAMemberComponent membershipPackageId={membershipPackageId} memberName={memberName} memberId={memberId} />
    </PrivateRoute>
  );
};

export default LTAMemberPage;
