import React, { useMemo } from 'react';

import { Grid } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
import FormErrorMessage from 'src/components/form-error-message/form-error-message';
import { DateField, RangeContainer } from 'src/components/formik-fields/formik-fields';
import * as yup from 'yup';

import { BodyRegularBold, TextInput } from '@clubspark-react/clubspark-react-tools';

import * as styles from './lta-payment-dialog.module.less';

export interface PaymentFormValues {
  amount: number;
  date?: Date;
}

export interface LTAPaymentDialogProps {
  values?: PaymentFormValues;
  onClose?: () => void;
  onSubmit?: (values: PaymentFormValues) => void;
  isSubmitting?: boolean;
}

const defaultValues: PaymentFormValues = {
  amount: 0,
  date: new Date(),
};

export const LTAPaymentDialog: React.FC<LTAPaymentDialogProps> = ({ values, isSubmitting, onSubmit, onClose }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        amount: yup.number().required(t('is required', { type: 'amount' })),
        date: yup.date().required(t('is required', { type: 'date' })),
      }),
    [t],
  );

  const initialValues = Object.assign({}, defaultValues, values);

  const handleSubmit = (values) => {
    if (typeof onSubmit === 'function') {
      onSubmit(values);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(props) => (
        <Form className={styles.contaier}>
          <LTAPaymentDialogForm {...props} />
          <Grid container className={styles.buttonContainer} justifyContent="flex-end">
            <Button onClick={onClose} type="button" level="tertiary">
              {t('cancel')}
            </Button>
            <Button type="submit" spacing={{ margins: { sm: 'left' } }} loading={isSubmitting}>
              {t('confirm')}
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

const LTAPaymentDialogForm: React.FC<FormikProps<PaymentFormValues>> = ({ values, errors, touched, children }) => {
  const { t } = useTranslation();

  return (
    <>
      <BodyRegularBold spacing={{ margins: { md: 'top', xs: 'bottom' } }}>{t('amount')} *</BodyRegularBold>
      <Field
        name="amount"
        // defaultValue={values.amount || ''}
        disabled
        disableUnderline
        outlined
        component={TextInput}
      />
      {errors?.amount && touched?.amount && (
        <FormErrorMessage spacing={{ margins: { xs: 'top' } }} message={errors?.amount} />
      )}
      <BodyRegularBold spacing={{ margins: { md: 'top', xs: 'bottom' } }}>{t('date')} *</BodyRegularBold>
      <RangeContainer>
        <DateField
          name="date"
          datePickerProps={{
            disabled: false,
            showTimeInput: false,
            selected: values.date ? new Date(values.date) : new Date(),
            popperPlacement: 'top',
          }}
        />
      </RangeContainer>
      {children}
    </>
  );
};

export default LTAPaymentDialog;
