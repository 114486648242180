import React, { useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Grid, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import AdminTable, { DataCols } from 'src/components/admin-table/admin-table';
import Breadcrumbs from 'src/components/breadcrumbs/breadcrumbs';
import CustomDialog from 'src/components/custom-dialog/custom-dialog';
import EmptyState from 'src/components/empty-state/empty-state';
import ExpansionList from 'src/components/expansion-list/expansion-list';
import { GET_MEMBERSHIP_PACKAGE_BY_ID } from 'src/components/lta-membership-details/lta-membership-details-queries';
import Panel from 'src/components/panel/panel';
import StatusLabel from 'src/components/status-label/status-label';
import { ControlProps, PaginationVariables, SortVariables } from 'src/components/table-controls/table-controls';
import { member as MemberResult, memberVariables as MemberVariables } from 'src/graphql-types/lta-registration/member';
import {
  membershipPackage as MembershipPackage,
  membershipPackageVariables as MembershipPackageVariables,
} from 'src/graphql-types/lta-registration/membershipPackage';
import {
  membershipPackageMember_membershipPackageMember_member as MembershipPackageMember,
  membershipPackageMember as MembershipPackageMemberResult,
  membershipPackageMemberVariables as MembershipPackageMemberVariables,
} from 'src/graphql-types/lta-registration/membershipPackageMember';
import { retrieveRowsPerPage } from 'src/utils/storage/local-storage';

import { BodySmall, BodySmallBold, H3, H4, localSort, Spinner } from '@clubspark-react/clubspark-react-tools';

import { getMemberStatusAndVariety, getPaymentLabelAndVariety, useMemberCols } from './lta-member-helpers';
import { GET_MEMBER, GET_PAYMENT_HISTORY } from './lta-member-queries';
import * as styles from './lta-member.module.less';
import LTAPaymentDialog, { PaymentFormValues } from './lta-payment-dialog';
import useUpdatePayment from './useUpdatePayment';

interface LTAMemberProps {
  membershipPackageId?: string;
  memberName?: string;
  memberId?: string;
}

const LTAMemberComponent: React.FC<LTAMemberProps> = ({ membershipPackageId, memberName, memberId }) => {
  const { t } = useTranslation();
  const hiddenSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const hiddenOnlyXs = useMediaQuery((theme) => theme.breakpoints.only('xs'));
  const storedRowsPerPage = useMemo(() => retrieveRowsPerPage(), []);
  const [ltaPaymentDialog, setLtaPaymentDialog] = useState(false);
  const [updatingPayment, setUpdatingPayment] = useState(false);

  const [memberToEdit, setMemberToEdit] = useState<MembershipPackageMember | null>(null);
  const [dataToDisplay, setDataToDisplay] = useState<MembershipPackageMember[]>();
  const { data, loading, error } = useQuery<MemberResult, MemberVariables>(GET_MEMBER, {
    fetchPolicy: 'no-cache',
    variables: {
      id: memberId || '',
    },
  });

  const {
    data: mPackageData,
    loading: mPackageLoading,
    error: mPackageError,
  } = useQuery<MembershipPackage, MembershipPackageVariables>(GET_MEMBERSHIP_PACKAGE_BY_ID, {
    variables: {
      id: membershipPackageId || '',
    },
  });

  const updatePayment = useUpdatePayment();

  const {
    data: paymentHistoryData,
    loading: paymentHistoryLoading,
    refetch,
  } = useQuery<MembershipPackageMemberResult, MembershipPackageMemberVariables>(GET_PAYMENT_HISTORY, {
    variables: {
      memberID: memberId || '',
      membershipPackageID: membershipPackageId || '',
    },
  });

  useEffect(() => {
    if (paymentHistoryData) {
      const members = (paymentHistoryData?.membershipPackageMember || [])
        .map((item) => item.member)
        .filter(Boolean)
        .slice(0, 0 + (storedRowsPerPage ?? 10)) as MembershipPackageMember[];

      setDataToDisplay(members);
    }
  }, [paymentHistoryData]);

  const onControlChange = (controls: { pagination?: PaginationVariables; sorting?: SortVariables }) => {
    let dataToSort = paymentHistoryData?.membershipPackageMember
      .map((item) => item.member)
      .filter(Boolean)
      .slice(controls.pagination?.offset, (controls.pagination?.limit ?? 10) + (controls.pagination?.offset ?? 0));

    if (controls.sorting) {
      dataToSort = localSort(dataToSort, controls.sorting.orderBy, controls.sorting.order);
    }

    // onControlChange triggers on every rerender, so we need to set
    // state only if object differs to avoid loop.
    if (JSON.stringify(dataToDisplay) !== JSON.stringify(dataToSort)) {
      setDataToDisplay(dataToSort as MembershipPackageMember[]);
    }
  };

  const controls: ControlProps = {
    onControlChange,
    paginaitonDisabled: paymentHistoryData ? paymentHistoryData?.membershipPackageMember.length < 10 : false,
    sortDisabled: false,
    totalItems: paymentHistoryData?.membershipPackageMember.length,
  };

  const membershipPackageName = mPackageData?.membershipPackage?.name ?? '';

  const { statusLabel, statusVariety } = getMemberStatusAndVariety(data?.member?.status);

  const { paymentLabel, paymentVariety } = getPaymentLabelAndVariety(data?.member?.paymentStatus);

  const handlePayClick = (member: MembershipPackageMember) => {
    setLtaPaymentDialog(true);
    setMemberToEdit(member);
  };

  const cols = useMemberCols({ onPayClick: handlePayClick });

  const costCols: DataCols<any> = useMemo(
    () => [
      { key: 'description', title: t('description'), getValue: (m) => m.description },
      { key: 'fullCost', title: t('full cost'), getValue: (m) => `£${m.fullCost.toFixed(2)}` },
      { key: 'amount', title: t('amount'), getValue: (m) => `£${m.amount.toFixed(2)}` },
    ],
    [t],
  );

  const costData = [
    {
      id: '',
      description: 'Membership fee',
      fullCost: data?.member?.totalCost,
      amount: data?.member?.paymentStatus === 'PAID' ? data?.member?.totalCost : 0,
    },
  ];

  const handlePaymentDialogSubmit = (values: PaymentFormValues) => {
    setUpdatingPayment(true);

    updatePayment(memberToEdit, values.date)
      .then(() => {
        handlePaymentDialogClose();
        refetch();
      })
      .finally(() => {
        setUpdatingPayment(false);
      });
  };

  const handlePaymentDialogClose = () => {
    setLtaPaymentDialog(false);
    setMemberToEdit(null);
  };

  if (loading || mPackageLoading) {
    return (
      <Grid container>
        <Spinner />
      </Grid>
    );
  }

  return (
    <>
      <Breadcrumbs
        paths={[
          { name: 'Memberships', to: '/lta-memberships' },
          { name: [membershipPackageName], to: `/lta-memberships/${membershipPackageId}` },
          { name: [memberName] },
        ]}
      />
      <Panel>
        <Grid container>
          <Grid container item xs>
            <H3>{memberName}</H3>
          </Grid>
        </Grid>
        <BodySmall>{membershipPackageName}</BodySmall>
        <div className={styles.divider} />
        <table className={styles.headerTable}>
          <thead>
            <tr>
              <th>
                <BodySmallBold>{t('status')}</BodySmallBold>
              </th>
              <th>
                <BodySmallBold>{t('start date')}</BodySmallBold>
              </th>
              <th>
                <BodySmallBold>{t('end date')}</BodySmallBold>
              </th>
              <th>
                <BodySmallBold>{t('payment')}</BodySmallBold>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <StatusLabel variety={statusVariety}>{statusLabel}</StatusLabel>
              </td>
              <td>
                <BodySmall>{dayjs.utc(mPackageData?.membershipPackage?.startDate).format('DD/MM/YYYY')}</BodySmall>
              </td>
              <td>
                <BodySmall>
                  {dayjs.utc(mPackageData?.membershipPackage?.renewsOn).subtract(1, 'days').format('DD/MM/YYYY')}
                </BodySmall>
              </td>
              <td>
                <BodySmall>
                  <StatusLabel variety={paymentVariety}>{paymentLabel}</StatusLabel>
                </BodySmall>
              </td>
            </tr>
          </tbody>
        </table>
      </Panel>
      <Panel>
        {error ? (
          <EmptyState title="There is no member" icon="lg-empty-state" />
        ) : (
          <>
            <Grid container alignItems="center">
              <H4 spacing={{ margins: { xs: 'bottom' } }}>Cost</H4>
            </Grid>
            {hiddenOnlyXs ? null : (
              <AdminTable
                columns={costCols}
                data={costData}
                onRowClick={() => {}}
                loading={loading || mPackageLoading}
              />
            )}
            {hiddenSmUp ? null : (
              <ExpansionList
                columns={cols}
                data={dataToDisplay ?? []}
                loading={loading || mPackageLoading}
                handleClick={() => {}}
                actions
              />
            )}
          </>
        )}
      </Panel>
      <Panel>
        {dataToDisplay?.length === 0 ? (
          <EmptyState title="There is no payment history for this organisation" icon="lg-empty-state" />
        ) : (
          <>
            <Grid container alignItems="center">
              <H4 spacing={{ margins: { xs: 'bottom' } }}>Payments and refunds</H4>
            </Grid>
            {hiddenOnlyXs ? null : (
              <AdminTable
                columns={cols}
                data={dataToDisplay ?? []}
                onRowClick={() => {}}
                loading={loading || mPackageLoading || paymentHistoryLoading}
                controls={controls}
              />
            )}
            {hiddenSmUp ? null : (
              <ExpansionList
                columns={cols}
                data={dataToDisplay ?? []}
                controls={controls}
                loading={loading || mPackageLoading || paymentHistoryLoading}
                handleClick={() => {}}
                actions
              />
            )}
            <CustomDialog
              title={t('add payment')}
              open={ltaPaymentDialog}
              hideX
              onClose={handlePaymentDialogClose}
              visibleOverflow
              content={
                <LTAPaymentDialog
                  values={{ amount: memberToEdit?.totalCost || 0 }}
                  isSubmitting={updatingPayment}
                  onSubmit={handlePaymentDialogSubmit}
                  onClose={handlePaymentDialogClose}
                />
              }
            />
          </>
        )}
      </Panel>
    </>
  );
};

export default LTAMemberComponent;
